<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
					</template>
					
					<template v-slot:right>
						<Button :label="$t('Nuevo')" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert"/>
					</template>
				
				</Toolbar>
				<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">{{$t('Quejas')}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="id" header="Nº" :sortable="true" headerStyle="width: 70px">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="poliza" :header="$t('Póliza')+' #'" :sortable="true" headerStyle="width: 200px">
						<template #body="slotProps">
							{{slotProps.data.codigo}}
						</template>
					</Column>
					<Column field="fecha" :header="$t('Fecha')" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.fecha}}
						</template>
					</Column>
					<Column field="caso" :header="$t('Departamento')" :sortable="true">
						<template #body="slotProps">
							<div v-if="i18n.locale() == 'es'">{{slotProps.data.caso_nombre}}</div>
							<div v-if="i18n.locale() == 'en'">{{slotProps.data.caso_name}}</div>
						</template>
					</Column>
					<Column :header="$t('Estatus')" headerStyle="width: 100px">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.estado">{{$t('Solución')}}</span>
							<span class="p-tag p-tag-danger" v-else>{{$t('Pendiente')}}</span>
						</template>
					</Column>
					<Column :header="$t('Acciones')" headerStyle="width: 100px">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-rounded p-button-success p-mr-2" @click="Ver(slotProps.data)" v-if="edit"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="$t('Quejas')" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-field p-col-4">
								<label>{{$t('Póliza')+' #'}}</label>
								<Dropdown id="state" v-model="product.poliza" :options="polizas" optionLabel="nombre" placeholder="Seleccione..." v-if="i18n.locale() == 'es'"></Dropdown>
								<Dropdown id="state" v-model="product.poliza" :options="polizas" optionLabel="name" placeholder="Select..." v-else></Dropdown>
							</div>
							<div class="p-field p-col-4">
								<label>{{$t('Departamento')}}</label>
								<Dropdown id="state" v-model="product.caso" :options="asunto" optionLabel="nombre" placeholder="Seleccione..." v-if="i18n.locale() == 'es'"></Dropdown>
								<Dropdown id="state" v-model="product.caso" :options="asunto" optionLabel="name" placeholder="Select..." v-else></Dropdown>
							</div>
							<div class="p-field p-col-4">
								<label>{{$t('Fecha en que ocurrió el incidente')}}</label>
								<InputMask v-model="product.fecha" placeholder="DD/MM/YYYY" slotChar="DD/MM/YYYY" mask="99/99/9999"/>
								<!--<InputText v-model="product.fecha" type="date"/>-->
							</div>
							<div class="p-field p-col-12">
								<label>{{$t('Describa los motivos de su queja')}}</label>
								<Textarea v-model="product.motivo" rows="3"/>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="verProductDialog" :style="{width: size}" :header="$t('Quejas')" :modal="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-field p-col-10">
								{{product.codigo}}
							</div>
							<div class="p-field p-col-2">
								<span class="p-tag p-tag-success" v-if="product.estado">{{$t('Solución')}}</span>
								<span class="p-tag p-tag-danger" v-else>{{$t('Pendiente')}}</span>
							</div>
							<div class="p-field p-col-12">
								{{$t('Fecha en que ocurrió el incidente')}}: {{product.fecha}}
							</div>
							<div class="p-field p-col-12">
								<div v-if="i18n.locale() == 'es'">{{$t('Asunto')}}: {{product.caso_nombre}}</div>
								<div v-if="i18n.locale() == 'en'">{{$t('Asunto')}}: {{product.caso_name}}</div>
							</div>
							<div class="p-field p-col-12">
								<label>{{$t('Describa los motivos de su queja')}}:</label>
								<br>
								{{product.motivo}}
							</div>
							<div class="p-field p-col-12 " v-if="product.estado">
								<label>{{$t('Solución')}}:</label>
								<br>
								{{product.respuesta}}
							</div>
						</div>	
					</div>
					<template #footer>
						<Button :label="$t('Cerrar')" icon="pi pi-times" class="p-button-danger" @click="verProductDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/ApiConsulta";
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				verProductDialog: false,
				product: {},
				selectedCustomers: null,
				filters: {},
				submitted: false,
				size: '60vw',
				polizas: [],
				asunto: []
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.i18n = useI18nPlugin();
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.$store.commit('Loading');
				this.asunto = [];
				this.polizas = [];
				const Consulta = new API('Quejas');
				Consulta.Ini('Queja').then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.polizas = result.consult.polizas;
					this.asunto = result.consult.asunto;
					if(result.consult.quejas){
						this.products = result.consult.quejas;
					}
					this.$store.commit('Loading');
				}); 
			},
			openNew() {
				this.nuevo = true;
				this.product = {};
				this.submitted = false;
				this.productDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			saveProduct() {
				if (this.product.poliza == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Póliza'), life: 10000});
				} else if (this.product.fecha == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Fecha'), life: 10000});
				} else if (this.product.caso == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Asunto'), life: 10000});
				} else if (this.product.motivo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: this.$t('Error en Motivo'), life: 10000});
				} else {			
					this.productDialog = false;
					const Consulta = new API('Quejas');          
					this.product.fecha = Consulta.fdate(this.product.fecha);
					Consulta.Procesar('Queja',{
						caso: 	'INS',
						queja: this.product
					}).then(response => {
						//this.$store.state.error =response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.Mostrar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
						}
					});
					this.productDialog = false;
					this.product = {};
				}
			},
			editProduct(product) {
				this.nuevo = false;
				this.product = {...product};
				this.productDialog = true;
			},
			Ver(product) {
				this.product = product;
				/*
                this.polizas.forEach(element => {
                    if(this.product.poliza==element.code){
                        this.product.poliza = element;
                    }
                });
                this.asunto.forEach(element => {
                    if(this.product.caso==element.code){
                        this.product.caso = element;
                    }
                });
				*/  
				this.verProductDialog = true;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
